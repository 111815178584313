export const SIDEBAR_MENU = [
  { title: "Directory", path: "/members" },
  // { title: "Groups", path: "/groups", disabled: true },
  { title: "Chart Management", path: "/charts" },
  { title: "Evaluations", path: "/evaluations" },
  { title: "CCC Evaluations", path: "/cccevaluationlist" },
  // {title:"My Program" ,path:"/myprogram"},
  // {title:"Directory" ,path:"/directory"},
  // {title:"Home" ,path:"/home"},

];

export const RESIDENT_MENU = [
  { title: "Evaluations", path: "/evaluations" },
];

export const FACULTY_MENU = [
  { title: "Evaluations", path: "/evaluations" },
];

export const MANAGER_MENU = [
  { title: "Directory", path: "/members" },
  // { title: "Groups", path: "/groups", disabled: true },
  { title: "Chart Management", path: "/charts" },
  { title: "Evaluations", path: "/evaluations" },
  { title: "CCC Evaluations", path: "/cccevaluationlist" },
  // {title:"My Program" ,path:"/myprogram"},
  // {title:"Directory " ,path:"/directory"},
  // {title:"Home" ,path:"/home"},

];
