import { Invitation } from "../../actionTypes";

const initialState = {
    institutionBasedPrograms: []
};

const InvitationReducer = (state = initialState, action) => {
    switch (action.type) {


        case Invitation.INSTITUTION_BASED_PROGRAM_LIST_REQUEST:
            return { isLoading: false };
        case Invitation.INSTITUTION_BASED_PROGRAM_LIST_SUCCESS:
            return { ...state, isLoading: false, institutionBasedPrograms: action.payload };
        case Invitation.INSTITUTION_BASED_PROGRAM_LIST_FAILURE:
            return { isLoading: false, error: action.payload };

        default:
            return state;
    }
};
export default InvitationReducer;
