import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import MaterialButton from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useState } from 'react';
import { uploadImage, uploadSignupImage } from '../../../store/actions/Member';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import UploadImage from "../../../Assets/Images/UploadImage.png";
import { ChangePassword, degreeList, disciplineList, getUser, profileUpdate, UserDetails, validateEmail, ValidatePassword } from '../../../store/actions/Auth';
import { useEffect } from 'react';
import close_ic from "../../../Assets/Images/close_ic.svg"
import edit_ic from "../../../Assets/Images/edit_ic.svg"
import save_ic from "../../../Assets/Images/save_ic.svg"
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import "../../../Components/pages/SignUp/signupstyle.css"
import "./header.css"
import default_img from "../../../Assets/Images/UploadImage.png"
import Imgicon from "../../../Assets/Images/addPhoto.svg";
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import { useMemo } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const MyProfile = (props) => {
  const { myProfileModalOpen, handleCloseMyProfile, setAnchorEl, handleMenuItemClick, listUser, setEditing, editing } = props
  useEffect(() => {
    if (myProfileModalOpen === true) {
      dispatch(UserDetails({ ID: localStorage.getItem("userId") }));
    }
  }, [])

  useEffect(() => {

  }, [listUser])

  const state = useSelector((state) => state)

  const firstName = listUser?.userDetails?.FirstName

  useEffect(() => {
    formikForGeneral.setValues({
      ...formikForGeneral.values,
      FirstName: listUser?.userDetails?.FirstName || "",
      LastName: listUser?.userDetails?.LastName || "",
    });
  }, [listUser]);


  const [members, setMembers] = useState({});
  const [passwordNotMatch, setPasswordNotMatch] = useState(false)
  const [currPassword, setCurrPassword] = useState(null)
  const [selectedOptionsDiscipline, setSelectedOptionsDiscipline] = useState([]);
  const [selectedOptionsDegree, setSelectedOptionsDegree] = useState([]);
  const [value, setValue] = React.useState('1');
  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [filetype, setFileType] = useState(null)
  const [filetypeTrue, setfileTypeTrue] = useState(false)
  const [filePicName, setFilePicName] = useState(false)
  const [disciplineValidation, setDesciplineValidation] = useState(false)
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [validationConfirmed, setValidationConfirmed] = useState(null)
  const [validationToggle, setValidationToggle] = useState(false)

  const disciplineIds = selectedOptionsDiscipline.map((item) => item.value);
  const degreeIds = selectedOptionsDegree.map((item) => item.value);

  const dispatch = useDispatch()
  const uploadFileName = state?.MemberReducer?.imageDetails?.fileName;

  const list = useSelector((state) => state?.AuthReducer?.list?.rows)
  const degreelist = useSelector((state) => state?.AuthReducer?.degreelist?.rows)

  const emailArry = listUser?.userDetails?.Email.split(",")
  const primaryEmail = emailArry[0];
  const secondaryEmail = emailArry[1];

  const formikForGeneral = useFormik({
    initialValues: {
      FirstName: listUser?.userDetails?.FirstName ? listUser?.userDetails?.FirstName : "",
      LastName: listUser?.userDetails?.LastName ? listUser?.userDetails?.LastName : "",
      PrimaryEmail: primaryEmail ? primaryEmail : "",
      SecondaryEmail: secondaryEmail ? secondaryEmail : "",
    },

    validationSchema: Yup.object({
      FirstName: Yup.string().required("First Name is Required"),
      LastName: Yup.string().required("Last Name is Required"),
      PrimaryEmail: Yup.string().email("Invalid Email").required("Primary Email is Required"),
    }),
    onSubmit: async (values) => {
      const reqestBody = {
        ID: localStorage.getItem("userId"),
        // UserTypeID: listUser?.userDetails?.UserTypeID,
        FirstName: formikForGeneral.values.FirstName,
        LastName: formikForGeneral.values.LastName,
        Picture: uploadFileName ? uploadFileName : listUser?.userDetails?.FileName,
        Email: formikForGeneral.values.SecondaryEmail
          ? `${formikForGeneral.values.PrimaryEmail},${formikForGeneral.values.SecondaryEmail}`
          : formikForGeneral.values.PrimaryEmail,
        Discipline: disciplineIds,
        Degree: degreeIds,
      };
      if (disciplineValidation === false) {
        await dispatch(profileUpdate(reqestBody));
        handleCloseMyProfile()
      }
      else {
        setDesciplineValidation(true)
      }
      await dispatch(UserDetails({ ID: localStorage.getItem("userId") }));
    },
  });

  const validateMessage = state?.AuthReducer?.passwordValidatefail?.success
  const validateMessageText = state?.AuthReducer?.passwordValidatefail?.message

  useEffect(() => {
    if (validateMessage === true) {
      handleCloseMyProfile()
    }
    else {
      setValidationConfirmed(validateMessageText)
      setValidationToggle(true)
    }
  }, [validateMessage])

  const formikForSecurity = useFormik({
    initialValues: {
      CurrentPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      CurrentPassword: Yup.string().required("This field is Required"),
      NewPassword: Yup.string().required("This field is Required"),
      ConfirmPassword: Yup.string().required("This field is Required"),
    }),

    onSubmit: (values) => {
      const reqBody = {
        CurrentPassword: formikForSecurity.values.CurrentPassword,
        NewPassword: formikForSecurity.values.NewPassword,
        ConfirmPassword: formikForSecurity.values.ConfirmPassword,
      }
      dispatch(ChangePassword(reqBody))
      handleCloseMyProfile()
    },
  });

  const PasswordVisibilityCurrent = () => {
    setShowPasswordCurrent(!showPasswordCurrent);
  };
  const PasswordVisibilityNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const PasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileUpload = async (event) => {
    setFile(event?.target?.files[0]);
    setFilePicName(event?.target?.files[0]?.name);
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileSize = file.size;
    setFileSize(fileSize)
    if (event?.target?.files[0]?.type === 'image/jpeg' ||
      event?.target?.files[0]?.type === 'image/jpg' ||
      event?.target?.files[0]?.type === 'image/png') {
      setfileTypeTrue(true)
    }
  };

  const disListForUpdate = listUser?.userDetails?.Discipline
  const degListForUpdate = listUser?.userDetails?.Degree

  const options = list?.map((item) => ({ value: item?.ID, label: item?.Name }));
  const optionsDegree = degreelist?.map((item) => ({ value: item?.ID, label: item?.Name }));

  useEffect(() => {
    if (disListForUpdate) {
      const defaultValues = disListForUpdate.map((item) => ({
        value: item?.ID,
        label: item?.Name,
      }));
      setSelectedOptionsDiscipline(defaultValues);
    }
  }, [disListForUpdate]);

  useEffect(() => {
    if (degListForUpdate) {
      const defaultValuesDeg = degListForUpdate.map((item) => ({
        value: item?.ID,
        label: item?.Name,
      }));
      setSelectedOptionsDegree(defaultValuesDeg);
    }
  }, [degListForUpdate]);

  async function onSelectDiscipline(selectedList) {
    setSelectedOptionsDiscipline(selectedList);
    const disIds = selectedList.length > 0 ? selectedList.map((item) => item.value) : [];
    if (selectedList.length === 0) {
      setDesciplineValidation(true)
    }
    else {
      setDesciplineValidation(false)

    }
  }

  async function onRemoveDiscipline(selectedList) {
    setSelectedOptionsDiscipline(selectedList);
    if (selectedList.length === 0) {
      setDesciplineValidation(true)
    }
    else {
      setDesciplineValidation(false)

    }
  }

  async function onSelectDegree(selectedList) {
    setSelectedOptionsDegree(selectedList);
    const degIds = selectedList.length > 0 ? selectedList.map((item) => item.value) : [];
    setMembers({ ...members, Degree: degIds });
  }

  async function onRemoveDegree(selectedList) {
    setSelectedOptionsDegree(selectedList);
  }

  const isPasswordValid = useMemo(
    () =>
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])(?=.*[a-z]).{8,}$/.test(
        formikForSecurity.values.NewPassword
      ),
    [formikForSecurity.values.NewPassword]
  );

  useEffect(() => {
    dispatch(disciplineList())
    dispatch(degreeList())
  }, [])

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("Picture", file);
      dispatch(uploadSignupImage(formData));
    }
  }, [file])

  const handleEditClick = () => {
    setEditing(true);
  }

  return (
    <Dialog
      open={myProfileModalOpen}
      onClose={handleCloseMyProfile}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MuiDialogTitle disableTypography style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h6" style={{ fontSize: "20px", fontWeight: "600", color: "#000", letterSpacing: "0.3px" }}>Profile Settings</Typography>
        <img src={close_ic} onClick={handleCloseMyProfile} style={{ cursor: "pointer" }}></img>
      </MuiDialogTitle>
      <DialogContent style={{ padding: "0" }}>
        <Grid>
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ padding: " 0 8px" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="General" value="1" />
                <Tab label="Security" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: "0" }}>
              <Grid style={{ padding: 0 }}>
                <Grid>
                  <form onSubmit={formikForGeneral.handleSubmit}>
                    <GeneralForm
                      handleFileUpload={handleFileUpload}
                      formikForGeneral={formikForGeneral}
                      onSelectDiscipline={onSelectDiscipline}
                      onRemoveDiscipline={onRemoveDiscipline}
                      selectedOptionsDiscipline={selectedOptionsDiscipline}
                      options={options}
                      onSelectDegree={onSelectDegree}
                      onRemoveDegree={onRemoveDegree}
                      selectedOptionsDegree={selectedOptionsDegree}
                      optionsDegree={optionsDegree}
                      listUser={listUser}
                      image={image}
                      fileSize={fileSize}
                      filetype={filetype}
                      filetypeTrue={filetypeTrue}
                      disciplineValidation={disciplineValidation}
                      editing={editing}
                    />
                    <Grid style={{ position: "sticky", bottom: 0, display: "flex", justifyContent: "space-between", alignItems: "center", padding: 10, backgroundColor: "#FAFAFA" }}>
                      {editing ? (
                        <>
                          <Grid>
                            <MaterialButton variant="outlined" onClick={handleCloseMyProfile}
                              style={{ minWidth: 79, height: 33, fontSize: 14, color: "#888888", borderColor: "#888888", }}>
                              Cancel
                            </MaterialButton>
                          </Grid>
                          <Grid>
                            <Button type="submit" variant="contained" color="primary" style={{ marginLeft: 10, minWidth: 79, height: 33 }}>
                              Save
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <MaterialButton variant="outlined" onClick={handleEditClick}
                            style={{
                              minWidth: 79,
                              height: 33,
                              fontSize: 14,
                              color: "#888888", // Text color
                              borderColor: "#888888", // Border color
                            }}
                          >
                            Edit
                          </MaterialButton>
                          <MaterialButton variant="outlined" color="error" onClick={() => handleMenuItemClick("logout")}
                            style={{
                              marginLeft: 10, minWidth: 79, height: 33,
                              fontSize: 14,
                            }} >
                            Logout
                          </MaterialButton>
                        </>
                      )}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid style={{ padding: 0 }}>
                <Grid >
                  <form onSubmit={formikForSecurity.handleSubmit}>
                    <SecurityForm
                      formikForSecurity={formikForSecurity}
                      setCurrPassword={setCurrPassword}
                      isPasswordValid={isPasswordValid}
                      passwordNotMatch={passwordNotMatch}
                      validationToggle={validationToggle}
                      PasswordVisibilityCurrent={PasswordVisibilityCurrent}
                      PasswordVisibilityNew={PasswordVisibilityNew}
                      PasswordVisibilityConfirm={PasswordVisibilityConfirm}
                      showPasswordCurrent={showPasswordCurrent}
                      showPasswordNew={showPasswordNew}
                      showPasswordConfirm={showPasswordConfirm}
                    />
                    <Grid style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                      <Button variant="contained" color="secondary" onClick={handleCloseMyProfile}
                        style={{
                          minWidth: 147, height: 33
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary"
                        style={{
                          minWidth: 147, height: 33,
                          marginLeft: 10
                        }}
                      >
                        Reset Password
                      </Button>

                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}

export default MyProfile

const GeneralForm = (props) => {
  const { formikForGeneral,
    options,
    onSelectDiscipline,
    onRemoveDiscipline,
    selectedOptionsDiscipline,
    onSelectDegree,
    onRemoveDegree,
    selectedOptionsDegree,
    optionsDegree,
    listUser,
    image,
    fileSize,
    filetypeTrue,
    disciplineValidation,
    handleFileUpload,
    editing,
  } = props;

  const data = listUser?.userDetails?.Picture
  console.log({ listUser })
  return (
    <>
      <div style={{ padding: "8px 24px" }}>
        <div style={{ display: "flex", alignItems: "center", columnGap: "12px" }}>
          <div className='invite_profile'>
            {image && fileSize <= 9900000 && filetypeTrue === true ? (
              <img src={image} style={{ height: "42px", width: "42px", borderRadius: "50%", border: "none" }} />
            ) : (
              <img src={listUser?.userDetails?.Picture ? listUser?.userDetails?.Picture : default_img} style={{ height: "42px", width: "42px", borderRadius: "50%", border: "none" }} />
            )}
          </div>

          <div className="upload__image-wrapper" style={{ marginTop: "20px" }}>
            <label htmlFor="fileInput" className="addPhotobuttonStyle">
              <img src={Imgicon} style={{ marginRight: 16 }}></img>
              Change Photo
            </label>
            <input id="fileInput" type="file" multiple style={{ display: "none" }} onChange={handleFileUpload} />
          </div>
        </div>

        <Grid style={{ margin: "20px 0", position: "relative" }} className='parentField'>
          <label>First Name</label>
          <TextField
            style={{
              borderColor: "transparent",
              backgroundColor: "#FAFAFA",
              borderRadius: "8px",
              boxShadow: "none",
              borderBottom: "none",
              marginTop: "5px"
            }}
            InputProps={{
              style: {
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                },
              },
              disableUnderline: true,
            }}
            id="outlined-basic"
            fullWidth
            size="small"
            name="FirstName"
            onChange={formikForGeneral.handleChange}
            onBlur={formikForGeneral.handleBlur}
            value={formikForGeneral.values.FirstName}
            disabled
          />
          {formikForGeneral.touched.FirstName && formikForGeneral.errors.FirstName ? (
            <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForGeneral.errors.FirstName}</div>
          ) : null}
          {/* <div style={{ position: "absolute", right: "15px", top: "32px" }}>
            <img src={edit_ic}></img>
            <span style={{ color: "#18A457", marginLeft: "5px" }}>Edit</span>
          </div> */}
        </Grid>

        <Grid style={{ margin: "20px 0", position: "relative" }} className='parentField'>
          <label>Last Name</label>
          <TextField
            style={{
              borderColor: "transparent",
              backgroundColor: "#FAFAFA",
              borderRadius: "8px",
              boxShadow: "none",
              borderBottom: "none",
              marginTop: "5px"
            }}
            InputProps={{
              style: {
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                },
              },
              disableUnderline: true,
            }}
            id="outlined-basic"
            fullWidth
            size="small"
            name="LastName"
            onChange={formikForGeneral.handleChange}
            onBlur={formikForGeneral.handleBlur}
            value={formikForGeneral.values.LastName}
            disabled
          />
          {formikForGeneral.touched.LastName && formikForGeneral.errors.LastName ? (
            <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForGeneral.errors.LastName}</div>
          ) : null}
          {/* <div style={{ position: "absolute", right: "15px", top: "32px" }}>
            <img src={save_ic}></img>
            <span style={{ color: "#18A457", marginLeft: "5px" }}>Save</span>
          </div> */}
        </Grid>

        <Grid style={{ margin: "20px 0" }} className='parentField'>
          <label>Work/Primary Email Address</label>
          <TextField
            style={{
              borderColor: "transparent",
              backgroundColor: "#FAFAFA",
              borderRadius: "8px",
              boxShadow: "none",
              borderBottom: "none",
              marginTop: "5px"
            }}
            InputProps={{
              style: {
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                },
              },
              disableUnderline: true,
            }}
            id="outlined-basic"
            fullWidth
            size="small"
            name="PrimaryEmail"
            onChange={formikForGeneral.handleChange}
            onBlur={formikForGeneral.handleBlur}
            value={formikForGeneral.values.PrimaryEmail}
            disabled
          />
          {formikForGeneral.touched.PrimaryEmail && formikForGeneral.errors.PrimaryEmail ? (
            <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForGeneral.errors.PrimaryEmail}</div>
          ) : null}
        </Grid>

        <Grid style={{ margin: "20px 0" }} className='parentField'>
          <label>Secondary Email Address</label>
          <TextField
            style={{
              borderColor: "transparent",
              backgroundColor: editing ? "#FFFFFF" : "#FAFAFA",
              borderRadius: "8px",
              boxShadow: "none",
              borderBottom: "none",
              marginTop: "5px"
            }}
            InputProps={{
              style: {
                border: editing ? "1px solid #D3D3D3" : "none",
                boxShadow: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                },
              },
              disableUnderline: true,
            }}
            id="outlined-basic"
            fullWidth
            size="small"
            name="SecondaryEmail"
            onChange={formikForGeneral.handleChange}
            value={formikForGeneral.values.SecondaryEmail}
            disabled={!editing} // Set the disabled prop based on the editing state
          />
        </Grid>
        <Grid style={{ margin: "20px 0" }}>
          <label >
            Disciplines<span class="required-label"></span>
          </label>
          <Multiselect
            options={options}
            selectedValues={selectedOptionsDiscipline}
            onSelect={onSelectDiscipline}
            onRemove={onRemoveDiscipline}
            displayValue="label"
            placeholder="Select options"
            closeIcon="cancel"
            name="Discipline"
            showCheckbox={true}
            selectionLimit={100}
            hidePlaceholder={false}
            disable={!editing}  // Set the disabled prop based on the editing state
            style={{
              multiselectContainer: {
                minWidth: "200px",
                // height: "50px",
              },
              chips: {
                background: "#E2E9EA",
                borderRadius: "4px",
                color: "#50676A"
              },
              searchBox: {
                border: editing ? "1px solid #ced4da" : "none",
                borderRadius: "4px",
                padding: "10px",
                // height: "50px",
                marginTop: "5px",
                backgroundColor: editing ? "#FFFFFF" : "#FAFAFA",
              },
              optionContainer: {
                border: "1px solid grey",
                borderRadius: "0px",
              },
              closeIcon: {
                color: "#50676A"
              },
            }}
          />
          {disciplineValidation === true ? <span style={{ color: "red" }}>Please select atleast one Discipline</span> : null}
        </Grid>

        <Grid style={{ margin: "20px 0" }}>
          <label>
            Degrees<span class="required-label"></span>
          </label>
          <Multiselect
            options={optionsDegree}
            selectedValues={selectedOptionsDegree}
            onSelect={onSelectDegree}
            onRemove={onRemoveDegree}
            displayValue="label"
            placeholder="Select options"
            closeIcon="cancel"
            showCheckbox={true}
            selectionLimit={100}
            hidePlaceholder={false}
            disable={!editing}  // Set the disabled prop based on the editing state
            style={{
              multiselectContainer: {
                minWidth: "200px",
                // height: "50px",
              },
              chips: {
                background: "#E2E9EA",
                borderRadius: "4px",
                color: "#50676A"
              },
              searchBox: {
                border: editing ? "1px solid #ced4da" : "none",
                borderRadius: "4px",
                padding: "10px",
                marginTop: "5px",
                backgroundColor: editing ? "#FFFFFF" : "#FAFAFA",
                // height: "50px",
              },
              optionContainer: {
                border: "1px solid grey",
                borderRadius: "0px",
              },
              closeIcon: {
                color: "#50676A"
              },
            }}
          />
        </Grid>
        <Grid style={{ margin: "20px 0" }}>
          {listUser?.Inviter.length > 0 &&
            listUser?.Inviter.map((list) => (
              <p style={{ color: "#888888", fontSize: "16px" }}>
                You have been invited by <span style={{ color: "#333333", fontWeight: "600" }}>{list.FirstName + " " + list.LastName}</span> from <span style={{ color: "#333333", fontWeight: "600" }}>{list.InstituitionName}</span> to take part in their <span style={{ color: "#333333", fontWeight: "600" }}>{list.ProgramName}</span> program as a <span style={{ color: "#333333", fontWeight: "600" }}>
                  {
                    list.UserTypeID == 1 ?
                      "Core Faculty" :
                      list.UserTypeID == 2 ?
                        "Resident" :
                        list.UserTypeID == 3 ?
                          "Program Manager" :
                          list.UserTypeID == 4 ?
                            "Program Director" :
                            list.UserTypeID == 5 ?
                              "Associates" :
                              list.UserTypeID == 6 ?
                                "Medical Student" :
                                null
                  }
                </span>
              </p>
            ))
          }
        </Grid>
      </div>
    </>
  );
};

const SecurityForm = (props) => {
  const {
    formikForSecurity,
    isPasswordValid,
    passwordNotMatch,
    validationConfirmed,
    validationToggle,
    PasswordVisibilityCurrent,
    PasswordVisibilityNew,
    PasswordVisibilityConfirm,
    showPasswordCurrent,
    showPasswordNew,
    showPasswordConfirm,
  } = props;

  return (
    <>
      <Grid>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ color: "#000", fontWeight: "600", fontSize: "16px", marginBottom: "10px" }}>Change Password</label>
          <label style={{ color: "#888", fontWeight: "400", fontSize: "14px" }}>To change your password please provide your current password,</label>
          <label style={{ color: "#888", fontWeight: "400", fontSize: "14px" }}>followed by your new password.</label>
        </div>
      </Grid>
      <Grid style={{ margin: "20px 0", position: "relative" }}>
        <label>Current Password</label>
        <TextField
          type={showPasswordCurrent ? "text" : "password"}
          style={{
            borderColor: "transparent",
            backgroundColor: "#FAFAFA",
            borderRadius: "8px",
            boxShadow: "none",
            borderBottom: "none",
            marginTop: "5px"
          }}
          InputProps={{
            style: {
              border: "none",
              boxShadow: "none",
              "&:hover": {
                border: "none",
                boxShadow: "none",
              },
            },
            disableUnderline: true,
            endAdornment: (
              <IconButton
                onClick={PasswordVisibilityCurrent}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          id="outlined-basic"
          fullWidth
          size="small"
          name="CurrentPassword"
          onChange={formikForSecurity.handleChange}
          onBlur={formikForSecurity.handleBlur}
          value={formikForSecurity.values.CurrentPassword}
        />
        {formikForSecurity.touched.CurrentPassword && formikForSecurity.errors.CurrentPassword ? (
          <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForSecurity.errors.CurrentPassword}</div>
        ) : null}
        {validationToggle === true ? <span style={{ color: "red" }}>{validationConfirmed}</span> : null}
      </Grid>

      <Grid style={{ margin: "20px 0", position: "relative" }}>
        <label>New Password</label>
        <TextField
          type={showPasswordNew ? "text" : "password"}
          style={{
            borderColor: "transparent",
            backgroundColor: "#FAFAFA",
            borderRadius: "8px",
            boxShadow: "none",
            borderBottom: "none",
            marginTop: "5px"
          }}
          InputProps={{
            style: {
              border: "none",
              boxShadow: "none",
              "&:hover": {
                border: "none",
                boxShadow: "none",
              },
            },
            disableUnderline: true,
            endAdornment: (
              <IconButton
                onClick={PasswordVisibilityNew}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPasswordNew ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          id="outlined-basic"
          fullWidth
          size="small"
          name="NewPassword"
          onChange={formikForSecurity.handleChange}
          onBlur={formikForSecurity.handleBlur}
          value={formikForSecurity.values.NewPassword}
        />
        <PasswordStrengthBar
          password={formikForSecurity.values.NewPassword}
          minLength={8}
          customStyle={["@"]}
          style={{ widows: "472px", marginTop: "10px" }}
        />
        {formikForSecurity.touched.NewPassword && formikForSecurity.errors.NewPassword ? (
          <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForSecurity.errors.NewPassword}</div>
        ) : null}
        {!isPasswordValid && formikForSecurity.values.NewPassword &&
          <div style={{ color: 'red', display: "flex", width: "460px" }}>
            Password length must be 8 characters, at least one uppercase letter, one digit, and one special character
          </div>}
      </Grid>

      <Grid style={{ margin: "20px 0", position: "relative" }}>
        <label>Confirm Password</label>
        <TextField
          type={showPasswordConfirm ? "text" : "password"}
          style={{
            borderColor: "transparent",
            backgroundColor: "#FAFAFA",
            borderRadius: "8px",
            boxShadow: "none",
            borderBottom: "none",
            marginTop: "5px"
          }}
          InputProps={{
            style: {
              border: "none",
              boxShadow: "none",
              "&:hover": {
                border: "none",
                boxShadow: "none",
              },
            },
            disableUnderline: true,
            endAdornment: (
              <IconButton
                onClick={PasswordVisibilityConfirm}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          id="outlined-basic"
          fullWidth
          size="small"
          name="ConfirmPassword"
          onChange={formikForSecurity.handleChange}
          onBlur={formikForSecurity.handleBlur}
          value={formikForSecurity.values.ConfirmPassword}
        />
        {formikForSecurity.touched.ConfirmPassword && formikForSecurity.errors.ConfirmPassword ? (
          <div className="errorMsg" style={{ color: 'red', color: "#F4586C", marginTop: "2px" }}>{formikForSecurity.errors.ConfirmPassword}</div>
        ) : null}
        {passwordNotMatch === true ?
          <div
            style={{ color: 'red', marginTop: "10px" }}>
            <span>Password and confirm password must be same</span>
          </div>
          : null}

      </Grid>
    </>
  )
}