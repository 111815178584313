import { Member } from "../../actionTypes";
import gateWay from "../../../Config/authorizationService";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { setSnackBarNotifications } from "../Global";

// For listing Institution in invite dropdown
export const listInstitutions = (params) => async (dispatch) => {
    await dispatch({ type: Member.INSTITUTION_LIST_REQUEST, isLoading: true });
    try {
        const res = await gateWay.get(userEndpoints.listOfInstitutions);
        const institutionList = res.data;
        if (institutionList.success) {
            await dispatch({ type: Member.INSTITUTION_LIST_SUCCESS, payload: institutionList });
        }
        // await dispatch({ type: Member.MEMBER_LIST, isLoading: false });
    } catch (error) {
        await dispatch({
            type: Member.INSTITUTION_LIST_FAILURE,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listMembers = (params) => async (dispatch) => {
    await dispatch({ type: Member.MEMBER_LIST, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.listMembers, params);
        const memberList = res.data;
        if (memberList.success) {
            await dispatch({ type: Member.MEMBER_LIST, data: memberList });
        }
        await dispatch({ type: Member.MEMBER_LIST, isLoading: false });
    } catch (e) {
        await dispatch({ type: Member.MEMBER_LIST, isLoading: false });
    }
};

// For listing roles in invite dropdown
export const listUsers = (params) => async (dispatch) => {
    await dispatch({ type: Member.USER_LIST_REQUEST, isLoading: true });
    try {
        const res = await gateWay.get(userEndpoints.listUsers + params);
        const userList = res.data;
        if (userList.success) {
            await dispatch({ type: Member.USER_LIST_SUCCESS, payload: userList });
        }
        // await dispatch({ type: Member.MEMBER_LIST, isLoading: false });
    } catch (error) {
        await dispatch({
            type: Member.USER_LIST_FAILURE,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// For listing programms in invite dropdown
export const listPrograms = (params) => async (dispatch) => {
    await dispatch({ type: Member.PROGRAM_LIST_REQUEST, isLoading: true });
    try {
        const res = await gateWay.get(userEndpoints.listPrograms + (params ? params : ""));
        const programList = res.data;
        if (programList.success) {
            await dispatch({ type: Member.PROGRAM_LIST_SUCCESS, payload: programList });
        }
    } catch (error) {
        await dispatch({
            type: Member.PROGRAM_LIST_FAILURE,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Program lists for header section
export const listOfPrograms = () => async (dispatch) => {
    await dispatch({ type: Member.HEADER_PROGRAM_LIST_REQUEST, isLoading: true });
    try {
        const res = await gateWay.get(userEndpoints.listPrograms);
        const headerPrograms = res.data;
        if (headerPrograms.success) {
            await dispatch({ type: Member.HEADER_PROGRAM_LIST_SUCCESS, payload: headerPrograms });
        }
    } catch (error) {
        await dispatch({
            type: Member.HEADER_PROGRAM_LIST_FAILURE,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listUserTypes = (params) => async (dispatch) => {
    await dispatch({ type: Member.USER_TYPES, isLoading: true });
    try {
        const res = await gateWay.get(userEndpoints.userTypes);
        const userTypes = res.data;
        if (userTypes.success) {
            await dispatch({ type: Member.USER_TYPES, data: userTypes });
        }
        await dispatch({ type: Member.USER_TYPES, isLoading: false });
    } catch (error) {
        await dispatch({
            type: Member.USER_TYPES, isLoading: false, payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// For sending invitation
export const sendInvite = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.INVITATION_REQUEST, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.sendInvitation, params);
        const invitation = res.data;
        if (invitation.success) {
            await dispatch({ type: Member.INVITATION_SUCCESS, data: invitation });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: invitation.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: invitation });
        } else {
            snackbarOptions = { ...snackbarOptions, variant: "error", message: invitation.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (error) {
        await dispatch({
            type: Member.INVITATION_FAILURE, isLoading: false, payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: error.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });
    }
};

// For Inviting user
export const inviteUser = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.INVITE_USER_REQUEST, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.userInvite, params);
        const userInvite = res.data;
        if (userInvite.success) {
            await dispatch({ type: Member.INVITE_USER_SUCCESS, data: userInvite });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: userInvite.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: userInvite });
        } else {
            snackbarOptions = { ...snackbarOptions, variant: "error", message: userInvite.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (error) {
        await dispatch({
            type: Member.INVITE_USER_FAILURE, isLoading: false, payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: error.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });

    }
};

export const addMember = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.ADD_MEMBER, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.addMember, params);
        const addMember = res.data;
        if (addMember.success) {
            await dispatch({ type: Member.ADD_MEMBER, data: addMember });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: addMember.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: addMember });
        } else {
            await dispatch({ type: Member.ADD_MEMBER, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: addMember.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (e) {
        await dispatch({ type: Member.ADD_MEMBER, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });
    }
};

export const uploadImage = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.uploadImage, params);
        const imageDetails = res.data;
        if (imageDetails.success) {
            await dispatch({ type: Member.UPLOAD_IMAGE, data: imageDetails });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: imageDetails.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: imageDetails });
        } else {
            await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: imageDetails.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (e) {
        await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });
    }
};

export const uploadSignupImage = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.uploadSignupImage, params);
        const imageDetails = res.data;
        if (imageDetails.success) {
            await dispatch({ type: Member.UPLOAD_IMAGE, data: imageDetails });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: imageDetails.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: imageDetails });
        } else {
            await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: imageDetails.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (e) {
        await dispatch({ type: Member.UPLOAD_IMAGE, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });
    }
};

export const resendMail = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.RESEND_MAIL, isLoading: true });
    try {
        const res = await gateWay.post(userEndpoints.resendMail, params);
        const mailResponse = res.data;
        if (mailResponse.success) {
            await dispatch({ type: Member.RESEND_MAIL, data: mailResponse });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: mailResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        } else {
            await dispatch({ type: Member.RESEND_MAIL, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: mailResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        }
    } catch (e) {
        await dispatch({ type: Member.RESEND_MAIL, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    }
};

export const archiveUser = (params, type) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.ARCHIVE_USER, isLoading: true });
    try {
        const res = await gateWay.put(userEndpoints.archieveUser, params);
        const archieveResponse = res.data;
        if (archieveResponse.success) {
            await dispatch({ type: Member.ARCHIVE_USER, data: params, typeOfMember: type });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: archieveResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        } else {
            await dispatch({ type: Member.ARCHIVE_USER, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: archieveResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        }
    } catch (e) {
        await dispatch({ type: Member.ARCHIVE_USER, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    }
};

export const deleteUser = (params) => async (dispatch) => {
    let snackbarOptions = { open: true };
    await dispatch({ type: Member.DELETE_USER, isLoading: true });
    try {
        const res = await gateWay.delete(userEndpoints.deleteUser + params);
        const deleteResponse = res.data;
        if (deleteResponse.success) {
            await dispatch({ type: Member.DELETE_USER, data: deleteResponse });
            snackbarOptions = { ...snackbarOptions, variant: "success", message: deleteResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: true, data: deleteResponse });
        } else {
            await dispatch({ type: Member.DELETE_USER, isLoading: false });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: deleteResponse.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            return Promise.resolve({ success: false });
        }
    } catch (e) {
        await dispatch({ type: Member.DELETE_USER, isLoading: false, error: e.response && e.response.data });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.data.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return Promise.resolve({ success: false });
    }
};

// For checking the user already exist in the program
export const validateUser = (params) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let snackbarOptions = { open: true };
        await dispatch({ type: Member.VALIDATE_USER_REQUEST, isLoading: true });
        try {
            const res = await gateWay.post(userEndpoints.validateUser, params);
            const userExist = res.data;
            if (userExist.success) {
                await dispatch({ type: Member.VALIDATE_USER_SUCCESS, isLoading: false, payload: userExist });
                snackbarOptions = { ...snackbarOptions, variant: "success", message: userExist.message };
                await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
                resolve({ success: true, data: userExist });
            } else {
                snackbarOptions = { ...snackbarOptions, variant: "error", message: userExist.message };
                await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
                resolve({ success: false });
            }
        } catch (error) {
            await dispatch({
                type: Member.VALIDATE_USER_FAILURE, isLoading: false, payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            snackbarOptions = { ...snackbarOptions, variant: "error", message: error.response.data.data.message };
            await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
            reject({ success: false });
        }
    })
};
