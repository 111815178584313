import { Auth, SET_PROGRAM_ID, SET_PROGRAM_LIST } from "../../actionTypes";

const initialState = {
  user: {},
  pswd: {},
  isLoading: false,
  signUpData: [],
  validateEmail: {},
  list: [],
  degreelist: [],
  emailrest: {},
  signUpDataFailure: {},
  programList: null,
  programId: null,
  passwordValid: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRAM_LIST:
      return {
        ...state,
        programList: action.programList,
      };
    case SET_PROGRAM_ID:
      return {
        ...state,
        programId: action.programId,
      };
    case Auth.LOGIN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Auth.LOGIN_SUCCESS:
      return {
        ...state,
        user: { success: true, ...action.loginAction },
      };
    case Auth.LOGIN_FAILURE:
      return {
        ...state,
        user: { success: false, ...action.error },
      };

    case Auth.LIST_USER_SUCCESS:
      return {
        ...state,
        userList: { success: true, ...action.data },
      };
    case Auth.LIST_USER_FAILURE:
      return {
        ...state,
        userList: { success: false, ...action.error },
      };
    case Auth.DETAIL_USER_SUCCESS:
      return {
        ...state,
        detailsList: { success: true, ...action.data },
      };
    case Auth.DETAIL_USER_FAILURE:
      return {
        ...state,
        detailsList: { success: false, ...action.error },
      };
    case Auth.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpData: { success: true, ...action.data },
      };
    case Auth.SIGNUP_FAILURE:
      return {
        ...state,
        signUpDataFailure: { success: true, ...action.error },
      };
    case Auth.VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        validateEmail: { success: true, ...action.error },
      };
    case Auth.DISCIPLINE_LIST_SUCCESS:
      return {
        ...state,
        list: { success: true, ...action.data },
      };
    case Auth.DEGREE_LIST_SUCCESS:
      return {
        ...state,
        degreelist: { success: true, ...action.data },
      };
    case Auth.PROFILE_UPDATE:
      return {
        ...state,
        profileDetails: { success: true, ...action.data },
      };
    case Auth.RESET__PASSWORD_LOADING:
      return {
        ...state,
      };
    case Auth.RESET__PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChange: { success: true, ...action.data },
      };
    case Auth.RESET__PASSWORD_FAILURE:
      return {
        ...state,
        passwordValidatefail: { success: false, ...action.data },
      };
    case Auth.DEGREE_LIST_SUCCESS:
      return {
        ...state,
        emailrest: { success: true, ...action.data },
      };

    default:
      return state;
  }
};
export default AuthReducer;
