import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { listPrograms } from '../../../store/actions/Member';
import { setProgramDetails, setProgramFullDetails } from '../../../store/actions/MyProgram';
import { useCallback } from 'react';
import { debounce } from "lodash";

const OwnDropDown = (props) => {
    const dispatch = useDispatch();
    const [programsList, setProgramsList] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([]);
    const [searchTextGet, setSearchTextGet] = useState(null)
    const [prograrmID, setProgramID] = useState("")
    const [openSelect, setOpenSelect] = useState(true)

    const programList = useSelector((state) => state?.MemberReducer?.programList);
    const selectedProgramID = useSelector(state => state?.MyProgramReducer?.selectedProgram)
    const programFullList = useSelector(state => state?.MyProgramReducer?.programFullList)

    const customOnClick = (data) => {
        setOpenSelect(openSelect ? false : true)
    }
    const customSelectItem = (data) => {
        setSelectedProgram(data?.label)
        setSelectedProgram(data?.label)
        setProgramID(data?.value)
        customOnClick()
    }
    const debouncedSave = useCallback(
        debounce((searchInput) => {
            let params = `?SearchTerm=${searchInput}`
            dispatch(listPrograms(params));
        }, 900),
        []
    );
    const customOnchangeText = (event) => {
        debouncedSave(event.target.value);
        setSearchTextGet(event.target.value)
    }
    useEffect(() => {
        if (!selectedProgramID) dispatch(listPrograms(""));
    }, []);

    useEffect(() => {
        const updatedProgramsList = programList?.data?.rows?.map((item) => ({
            value: item.ID,
            label: item.Name,
        }))
        setProgramsList(updatedProgramsList);

        // Set the default value as the first program's ID
        if (updatedProgramsList?.length > 0 && searchTextGet === null) {
            setSelectedProgram(programFullList ? programFullList : updatedProgramsList[0].label);
            setProgramID(selectedProgramID ? selectedProgramID : updatedProgramsList[0].value);
        }

    }, [programList])

    useEffect(() => {   // Program id and fulldetails storing
        if (prograrmID && selectedProgram) {
            dispatch(setProgramDetails(prograrmID))
            dispatch(setProgramFullDetails(selectedProgram))
        }
    }, [prograrmID, selectedProgram])
    return (
        <>
            <div className="selectWrapper" style={{ position: "relative", height: "44px", width: "280px", border: "1px solid #3c3c3c", cursor: "pointer", padding: "0 5px", zIndex: 1, }}
                onClick={() => customOnClick()}
            >
                <span className="textOverflow">{selectedProgram}</span>
            </div>
            {
                openSelect === false ?
                    <div style={{ position: "absolute", top: "59px", background: "#fff", border: "1px solid #3c3c3c", padding: "10px 0", width: "280px", right: props?.hidingStyle ? "" : 88, zIndex: 2 }}>
                        <div style={{ padding: "0 5px" }}>
                            <input
                                type="text"
                                style={{ width: "268px", height: "40px", fontSize: "16px", marginBottom: "5px", padding: "0 10px" }}
                                onChange={customOnchangeText}
                            >
                            </input>
                        </div>
                        {programList?.data?.rows?.length > 0 ?
                            <ul style={{ margin: "0", listStyle: "none", padding: "0", maxHeight: "450px", minHeight: "70px", overflow: "auto" }}>
                                {programsList?.map((data) => (
                                    <li
                                        className="customMenuItem"
                                        key={data.value}
                                        style={{ cursor: "pointer", paddingBottom: "5px" }}
                                        onClick={() => customSelectItem(data)}
                                    >
                                        {data.label}</li>
                                ))}
                            </ul>
                            :
                            <div style={{ padding: "10px", display: "grid", placeContent: "center" }}>
                                <span>No Data to Show</span>
                            </div>
                        }

                    </div >
                    :
                    null
            }
        </>
    )
}

export default OwnDropDown
