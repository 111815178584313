import { MyProgram } from "../../actionTypes";

const initialState = {
  programList: {},
  listDepartment: {},
  graduationDate: {},
  // selectedProgram: {},
  programFullList: {},
};

const MyProgramReducer = (state = initialState, action) => {
  switch (action.type) {

    case MyProgram.DEPARTMENT_LIST_REQUEST:
      return { isLoading: true };
    case MyProgram.DEPARTMENT_LIST_SUCCESS:
      return { ...state, isLoading: false, listDepartment: action.payload };
    case MyProgram.DEPARTMENT_LIST_FAILURE:
      return { isLoading: false, error: action.payload };

    case MyProgram.PROGRAM_LIST_REQUEST:
      return { isLoading: true };
    case MyProgram.PROGRAM_DETAILS:
      return { ...state, isLoading: false, selectedProgram: action.payload };
    case MyProgram.PROGRAM_FULL_DETAILS:
      return { ...state, isLoading: false, programFullList: action.payload };
    case MyProgram.PROGRAM_LIST_SUCCESS:
      return { ...state, isLoading: false, programList: action.payload };
    case MyProgram.PROGRAM_LIST_FAILURE:
      return { isLoading: false, error: action.payload };

    case MyProgram.GET_GRADUATION_DATE_REQUEST:
      return { isLoading: true };
    case MyProgram.GET_GRADUATION_DATE_SUCCESS:
      return { ...state, isLoading: false, graduationDate: action.payload };
    case MyProgram.GET_GRADUATION_DATE_FAILURE:
      return { isLoading: false, error: action.payload };


    // case MyProgram.MEMBER_LIST:
    //   return { isLoading: true };
    // case MyProgram.USER_TYPES:
    //   return { isLoading: true };
    // case MyProgram.INVITE_CREATE_REQUEST:
    //   return { isLoading: true };
    // case MyProgram.INVITE_CREATE_SUCCESS:
    //   return { isLoading: false, data: action.payload };
    // case MyProgram.INVITE_CREATE_FAILURE:
    //   return { isLoading: false, error: action.payload };
    // case MyProgram.SEND_INVITE_REQUEST:
    //   return { isLoading: true };
    // case MyProgram.SEND_INVITE_SUCCESS:
    //   return { isLoading: false, data: action.payload };
    // case MyProgram.SEND_INVITE_FAILURE:
    //   return { isLoading: false, error: action.payload };
    // case MyProgram.FILTER_LIST_REQUEST:
    //   return { isLoading: true };
    // case MyProgram.FILTER_LIST_SUCCESS:
    //   return { isLoading: false, data: action.payload };
    // case MyProgram.FILTER_LIST_FAILURE:
    //   return { isLoading: false, error: action.payload };
    default:
      return state;
  }
};
export default MyProgramReducer;
