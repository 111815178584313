const userEndpoints = {
  login: "/user/login",
  logout: "/userLogout",
  listUser: "/user/details",
  UserDetails: "/user/userDetails",
  chartSave: "/charts/save",
  chartList: "/charts/list",
  chartDetails: "/charts/details",
  updateChartStatus: "/charts/updateStatus",
  saveContext: "/context/save",
  listContext: "/context/list",
  deleteContext: "/context/delete",
  listMembers: "/user/list",
  addMember: "/user/save",
  profileUpdate: "/user/save",
  uploadImage: "/user/upload",
  uploadSignupImage: "/user/uploadImage",
  setPassword: "/user/setPassword",
  userTypes: "/usertype/list",
  forgotPassword: "/user/forgotpassword",
  resetpassword: "/user/resetpassword",
  resendMail: "/user/resendMail",
  validLink: "/user/validLink",
  archieveUser: "/user/archieveUser",
  deleteUser: "/user/delete",
  growthChartDetails: "/evaluation/graph",
  growthChartUserList: "/evaluation/evalualeeUserList",
  growthChartContext: "/context/list",
  growthChartEvaluationDetails: "/evaluation/getEvaluationDetails",
  getEvaluatedUserList: "/evaluation/evaluatedUserList",
  evaluationNarrativesDetails: "charts/details",
  onSaveEvaluation: "/evaluation/saveCCC",
  getCCCValuations: "/evaluation/getCCCValuations",
  publishCCC: "evaluation/publishCCCEvaluation",
  getCCCPublishedDetails: "evaluation/getCCCPublishedDetails",
  cccListing: "evaluation/cccListing",
  unEvaluatedList: "evaluation/cccUnevaluatedcharts",
  saveFeedback: "evaluation/saveFeedback",
  evaluatedList: "evaluation/getcccconfirmationdetails",
  startCCCList: "evaluation/residentsList",
  getListChartDetails: "charts/listChartDetails",
  getFeedbackDetails: "evaluation/list",
  getHandOff: "handoff/list",
  evaluationDetails: "evaluation/getCCCDetails",
  listDepartment: "/program/list",
  myProgramsList: "userProgramMapping/list",
  listUsers: "/user/allUserList",
  listPrograms: "/program/list",
  sendInvitation: "/userProgramMapping/save",
  userInvite: "/user/inviteUser",
  // signUp: "/user/getInvitationDetails?PasswordLink=ssSWtQz9k8GGfzgO0gmD&ProgramID=1",
  signUp: "/user/getInvitationDetails?",
  validateEmail: "/user/validateEmail",
  disciplineList: "/discipline/list",
  degreeList: "/degree/list",
  emailresetpassword: "/user/setPassword",
  validateUser: "/userProgramMapping/validateSave",
  getGraduationDate: "/user/getGraduationDate",

  changePassword: "/user/changePassword",
  listOfInstitutions: "/user/myInstitution",
  institutionBasedPrograms: "/program/institutionBasedProgram"
};

export { userEndpoints };
