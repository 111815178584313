import React, { useCallback, useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
// import { headerData } from "./helper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./header.css";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import NotificationIcon from "../../../Assets/Svg/notificationIcon"
import RGCLogo from '../../../Assets/Images/DashLogo.png'
// import CloseIcon from "@material-ui/icons/Close";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import { connect, useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../../store/actions/Global";
import SimpleListMenu from "../Menu";
import { UserDetails, getUser, logout, setProgramId } from "../../../store/actions/Auth";
import DialogNotification from "../Modal";
import { listPrograms, listOfPrograms } from "../../../store/actions/Member";
import { setProgramDetails, setProgramFullDetails } from "../../../store/actions/MyProgram";
import MyProfile from "./myProfile";
import Select, { createFilter } from 'react-select';
import { debounce } from "lodash";
import { event } from "jquery";
import OwnDropDown from "../ReactSelect";

const useStyles = makeStyles(() => ({
  appBar: {
    width: "100%",
    zIndex: 10,
  },
  listRoot: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    /*  marginLeft: "2%", */
    cursor: "pointer",
  },
  regular: {
    height: 80,
  },
}));

const Header = (props) => {
  const [programsList, setProgramsList] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [searchTextGet, setSearchTextGet] = useState(null)
  const [hidingStyle, setHidingStyle] = useState(false)
  const [editing, setEditing] = useState(false)


  const history = useHistory();
  const dispatch = useDispatch();

  const programList = useSelector((state) => state?.MemberReducer?.programList);
  const selectedProgramID = useSelector(state => state?.MyProgramReducer?.selectedProgram)
  const programFullList = useSelector(state => state?.MyProgramReducer?.programFullList)


  const state = useSelector((state) => state)
  console.log("cgec", state?.MyProgramReducer)
  const loginDetails = state?.AuthReducer?.detailsList?.userDetails
  const programIDRedux = state?.AuthReducer?.programId
  // const headerPrograms = useSelector((state) => state?.MemberReducer?.headerPrograms);

  console.log("State check for updation", state)

  const [anchorEl, setAnchorEl] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [myProfileModalOpen, setMyProfileModalOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const [prograrmID, setProgramID] = useState("")


  // const handleRouteChange = (route) => {
  //   props.setRoute(route);
  //   history.push(route);
  // };

  const classes = useStyles();
  const matches = useMediaQuery("(max-width:767px)");
  const { setOpen } = props;

  // useEffect(() => {
  //   dispatch(listPrograms(""));
  // }, []);

  // useEffect(() => {
  //   const updatedProgramsList = programList?.data?.rows?.map((item) => ({
  //     value: item.ID,
  //     label: item.Name,
  //   }))
  //   setProgramsList(updatedProgramsList);

  //   // Set the default value as the first program's ID
  //   if (updatedProgramsList?.length > 0 && searchTextGet === null) {
  //     setSelectedProgram(programFullList ? programFullList : updatedProgramsList[0].label);
  //     setProgramID(selectedProgramID ? selectedProgramID : updatedProgramsList[0].value);
  //   }

  // }, [programList])


  useEffect(() => {
    if (!matches && sideDrawerOpen) {
      setSideDrawerOpen(false);
    }
  }, [matches, sideDrawerOpen]);

  const componentDidMount = async () => {
    let userId = localStorage.getItem("userId");
    await props.UserDetails({ ID: userId });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const handleMenuItemClick = (options) => {
    if (options === "logout") {
      setLogoutModalOpen(true)
    }
  };


  const onRenderComponent = () => {
    return (
      <Grid style={{ minHeight: 80, padding: 20, display: "flex", alignItems: "center" }}>
        <Typography variant="body2" color="textSecondary">
          Are you sure you want to logout?
        </Typography>
      </Grid>
    );
  };

  const handleDialogWindowClose = () => {
    setLogoutModalOpen(false);
  };

  const onDialogActionsOnClick = async () => {
    await props.logout();
    localStorage.clear();
    history.push("/");
  };

  // useEffect(() => {   // Program id and fulldetails storing
  //   if (prograrmID && selectedProgram) {
  //     dispatch(setProgramDetails(prograrmID))
  //     dispatch(setProgramFullDetails(selectedProgram))
  //   }
  // }, [prograrmID, selectedProgram])


  // const debouncedSave = useCallback(
  //   debounce((searchInput) => {
  //     let params = `?SearchTerm=${searchInput}`
  //     dispatch(listPrograms(params));
  //   }, 2000),
  //   []
  // );

  // const onChangeProgram = async (event) => {
  //   let programId = event.value;
  //   setSelectedProgram(event);
  //   setProgramID(programId);
  //   dispatch(setProgramId(programId));
  //   dispatch(setProgramFullDetails(event));
  //   setSearchTextGet()
  // };

  // const onInputChange = (text) => {
  //   debouncedSave(text);
  //   setSearchTextGet(true)
  // }

  const handleCloseMyProfile = () => {
    setMyProfileModalOpen(false)
    setEditing(false)
  }

  // const [openSelect, setOpenSelect] = useState(true)
  // const customOnClick = (data) => {
  //   setOpenSelect(openSelect ? false : true)
  // }

  // const closeCustomDrop = () => {
  //   setOpenSelect(false)
  // }
  // const [customMenuData, setCustomMenuDataLabel] = useState(null)
  // const [customMenuDataID, setCustomMenuDataID] = useState(null)
  // const customSelectItem = (data) => {
  //   setSelectedProgram(data?.label)
  //   setSelectedProgram(data?.label)
  //   setProgramID(data?.value)
  //   setCustomMenuDataLabel(data?.label)
  //   setCustomMenuDataID(data?.value)
  //   customOnClick()
  // }

  // const customOnchangeText = (event) => {
  //   debouncedSave(event.target.value);
  //   setSearchTextGet(event.target.value)
  //   setCustomMenuDataLabel(event.target.value)
  // }

  console.log({ selectedProgram })
  return (
    <>
      <CssBaseline />
      <AppBar color="inherit" position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.regular}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            <img className={classes.logo} src={RGCLogo} />

            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

              {matches ? (
                <IconButton style={{ margin: "0px 5px" }} onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
              ) : null}

              {/* <Select
                value={selectedProgram}
                // defaultValue={selectedProgram}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '200px'
                  })
                }}
                onChange={(event) => onChangeProgram(event)}
                onInputChange={(event) => onInputChange(event)}
                options={programsList}
                filterOption={createFilter({ ignoreAccents: false })}
              /> */}
              {/* <div className="selectWrapper" style={{ position: "relative", height: "44px", width: "280px", border: "1px solid #3c3c3c", cursor: "pointer", padding: "0 5px" }}
                onClick={() => customOnClick()}
              >
                <span className="textOverflow">{selectedProgram}</span>
              </div>
              {
                openSelect === false ?
                  <div style={{ position: "absolute", top: "59px", background: "#fff", border: "1px solid #3c3c3c", padding: "10px 0", width: "280px", right: "88px" }}>
                    <div style={{ padding: "0 5px" }}>
                      <input
                        type="text"
                        style={{ width: "268px", height: "40px", fontSize: "16px", marginBottom: "5px" }}
                        onChange={customOnchangeText}
                      >
                      </input>
                    </div>
                    {programList?.data?.rows?.length > 0 ?
                      <ul style={{ margin: "0", listStyle: "none", padding: "0", height: "450px", overflow: "auto" }}>
                        {programsList?.map((data) => (
                          <li
                            className="customMenuItem"
                            key={data.value}
                            style={{ cursor: "pointer", paddingBottom: "5px" }}
                            onClick={() => customSelectItem(data)}
                          >
                            {data.label}</li>
                        ))}
                      </ul>
                      :
                      <div>
                        <span>No Data to Show</span>
                      </div>
                    }

                  </div>
                  :
                  null
              } */}

              <OwnDropDown hidingStyle={hidingStyle} />

              {/* <Select2
                defaultValue={selectedProgram}
                style={{ border: "none", width: "250px" }}
                data={programsList}
                disabled={props?.disabled ? true : false}
                options={{
                  placeholder: "ALL",
                }}
                onChange={onChangeProgram}
              /> */}

              {/* Right side notification button and my profile for logout */}
              <Grid>
                <IconButton onClick={() => setMyProfileModalOpen(!myProfileModalOpen)}>
                  <Avatar title={loginDetails?.FirstName + " " + loginDetails?.LastName} src={loginDetails?.Picture} />
                </IconButton>

                {/* commenting for implement my profile */}
                {anchorEl ? (
                  <SimpleListMenu anchorEl={anchorEl} options={[{ id: "logout", title: "Logout" }]} handleMenuItemClick={(options) => handleMenuItemClick(options)} onClose={() => setAnchorEl(null)} />
                ) : null}


                {myProfileModalOpen ? (
                  <MyProfile
                    myProfileModalOpen={myProfileModalOpen}
                    handleCloseMyProfile={handleCloseMyProfile}
                    handleMenuItemClick={handleMenuItemClick}
                    setAnchorEl={setAnchorEl}
                    listUser={props.listUser}
                    setEditing={setEditing}
                    editing={editing}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar >

      {
        logoutModalOpen ? (
          <DialogNotification
            // hideDividers
            dialogActions={
              [
                { text: "No", onClick: () => handleDialogWindowClose() },
                { text: "Yes", onClick: () => onDialogActionsOnClick(), proceedAction: true },
              ]}
            subRendercomponent={onRenderComponent()}
            title="Logout?"
            dialogRootProps={{ open: logoutModalOpen, onClose: () => setLogoutModalOpen(false) }}
          />
        ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    route: state.global.route,
    listUser: state.AuthReducer.detailsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (data) => dispatch(setRoute(data)),
    logout: () => dispatch(logout()),
    getUser: (data) => dispatch(getUser(data)),
    UserDetails: (data) => dispatch(UserDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
