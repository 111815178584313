import React from "react";

import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import global from "../../store/reducers/Global";
import AuthReducer from "../../store/reducers/Auth";
import ChartReducer from "../../store/reducers/Chart";
import MemberReducer from "../../store/reducers/Member";
import SetPasswordReducer from "../../store/reducers/SetPassword";
import growthChartReducer from "../../store/reducers/GrowthChart";
import CCCEvaluationReducer from "../../store/reducers/CCCEvaluations";
import MyProgramReducer from "../../store/reducers/MyProgram";
import DirectoryReducer from "../../store/reducers/Directory";
import InvitationReducer from "../../store/reducers/Invitation";



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  global,
  AuthReducer,
  ChartReducer,
  MemberReducer,
  SetPasswordReducer,
  growthChartReducer,
  CCCEvaluationReducer,
  MyProgramReducer,
  DirectoryReducer,
  InvitationReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const ProvideStore = (props) => {
  return <Provider store={store}>{props.children}</Provider>;
};

export default ProvideStore;
