import axios from "axios";
import gateWay from "../../../Config/authorizationService";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { MyProgram } from "../../actionTypes";

export const setProgramDetails = (params) => (dispatch) => {  // Program ID only storing here\
  dispatch({ type: MyProgram.PROGRAM_DETAILS });

  if (params) dispatch({ type: MyProgram.PROGRAM_DETAILS, payload: params });

};

export const setProgramFullDetails = (params) => (dispatch) => {  // Program full details storing here
  try {
    dispatch({ type: MyProgram.PROGRAM_FULL_DETAILS });

    if (params) dispatch({ type: MyProgram.PROGRAM_FULL_DETAILS, payload: params });

  } catch (error) {
    console.error(error, "errordisplying")
  }

};

export const listDepartments = () => async (dispatch) => {

  await dispatch({ type: MyProgram.DEPARTMENT_LIST_REQUEST });

  try {
    const { data } = await gateWay.get(userEndpoints.listDepartment);
    if (data.success) {
      await dispatch({ type: MyProgram.DEPARTMENT_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    await dispatch({
      type: MyProgram.DEPARTMENT_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listMyPrograms = (params) => async (dispatch) => {
  dispatch({ type: MyProgram.PROGRAM_LIST_REQUEST });
  try {
    const { data } = await gateWay.get(userEndpoints.myProgramsList + params);
    if (data.success) {
      dispatch({ type: MyProgram.PROGRAM_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: MyProgram.PROGRAM_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEstimatedGraduationDate = (params) => async (dispatch) => {
  dispatch({ type: MyProgram.GET_GRADUATION_DATE_REQUEST });
  try {
    const { data } = await gateWay.get(userEndpoints.getGraduationDate + params);
    console.log(data, "graduation Date check")
    if (data.success) {
      dispatch({ type: MyProgram.GET_GRADUATION_DATE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: MyProgram.GET_GRADUATION_DATE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// export const listMembers = () => async (dispatch) => {
//   await dispatch({ type: MyProgram.MEMBER_LIST });
//   try {
//     const data = axios.get("pages/Myprogram/index");

//     if (data) {
//       await dispatch({ type: MyProgram.MEMBER_LIST, payload: data });
//     }

//   } catch (error) {
//     await dispatch({
//       type: MyProgram.MEMBER_LIST,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const listUserTypes = () => async (dispatch) => {
//   await dispatch({ type: MyProgram.USER_TYPES });
//   try {
//     const data = axios.get("pages/Myprogram/index");

//     if (data) {
//       await dispatch({ type: MyProgram.USER_TYPES, payload: data });
//     }

//   } catch (error) {
//     await dispatch({
//       type: MyProgram.USER_TYPES,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const createInvite = () => async (dispatch) => {
//   dispatch({ type: MyProgram.INVITE_CREATE_REQUEST });
//   try {
//     const data = axios.post("pages/Myprogram/index");

//     if (data) {
//       dispatch({ type: MyProgram.INVITE_CREATE_SUCCESS, payload: data });
//     }

//   } catch (error) {
//     await dispatch({
//       type: MyProgram.ADD_INVITE_FAILURE,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const sendInvite = () => async (dispatch) => {
//   dispatch({ type: MyProgram.SEND_INVITE_REQUEST })
//   try {
//     const data = axios.post("pages/Myprogram/index");
//     if (data) {
//       dispatch({ type: MyProgram.SEND_INVITE_SUCCESS, payload: data });
//     }
//   }
//   catch (error) {
//     dispatch({
//       type: MyProgram.SEND_INVITE_FAILURE,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     })
//   }
// }

// export const filterMyProgramList = () => async (dispatch) => {
//   dispatch({ type: MyProgram.FILTER_LIST_REQUEST });
//   try {
//     const data = axios.get("pages/myprogram/index");
//     if (data) {
//       dispatch({ type: MyProgram.FILTER_LIST_SUCCESS, payload: data })
//     }
//   }
//   catch (error) {
//     await dispatch({
//       type: MyProgram.FILTER_LIST_FAILURE,
//       payload: error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message,
//     })
//   }
// }