import { Auth, SET_PROGRAM_ID, SET_PROGRAM_LIST } from "../../actionTypes";
import loginData from "../../../Config/util";
import gateWay, { authenticator } from "../../../Config/authorizationService";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { setSnackBarNotifications } from "../Global";

export const setProgramId = (programId) => ({
  type: SET_PROGRAM_ID,
  programId,
});

export const login = (param) => async (dispatch) => {
  let snackbarOptions = { open: true };
  await dispatch({ type: Auth.LOGIN_LOADING, isLoading: true });
  try {
    const res = await authenticator.post(userEndpoints.login, param);
    const userDetails = res.data;
    if (userDetails.success) {
      snackbarOptions = {
        ...snackbarOptions,
        variant: "success",
        message: userDetails?.message,
      };
      loginData(userDetails);
      await dispatch({
        type: Auth.LOGIN_SUCCESS,
        loginAction: userDetails.data,
      });
    } else {
      snackbarOptions = {
        ...snackbarOptions,
        variant: "error",
        message: userDetails?.message,
      };
      await dispatch({ type: Auth.LOGIN_FAILURE, error: userDetails });
    }
    await dispatch({ type: Auth.LOGIN_LOADING, isLoading: false });
    dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    return Promise.resolve({
      success: res?.data?.success || false,
      loginAction: userDetails.data,
    });
  } catch (e) {
    await dispatch(
      setSnackBarNotifications({
        open: true,
        variant: "error",
        message: "Something went wrong!",
      })
    );
    await dispatch({
      type: Auth.LOGIN_FAILURE,
      error: e.response && e.response.data,
    });
  }
};

export const logout = (param) => async (dispatch) => {
  await dispatch({ type: Auth.LOGIN_SUCCESS, loginAction: { success: false } });
};

export const getUser = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.listUser, { params });
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({
          type: Auth.LIST_USER_SUCCESS,
          data: userDetails.data,
        });
      } else {
        await dispatch({ type: Auth.LIST_USER_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.LIST_USER_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({
      type: Auth.LIST_USER_FAILURE,
      error: e.response && e.response.data,
    });
  }
};

export const UserDetails = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.UserDetails, { params });
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({
          type: Auth.DETAIL_USER_SUCCESS,
          data: userDetails.data,
        });
      } else {
        await dispatch({ type: Auth.DETAIL_USER_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.DETAIL_USER_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({
      type: Auth.LIST_USER_FAILURE,
      error: e.response && e.response.data,
    });
  }
};

export const SignUpUser = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.signUp + params);
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({ type: Auth.SIGNUP_SUCCESS, data: userDetails });
      } else {
        await dispatch({ type: Auth.SIGNUP_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.SIGNUP_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({
      type: Auth.SIGNUP_FAILURE,
      error: e.response && e.response.data,
    });
  }
};
// export const SignUpUser = (params) => async (dispatch) => {
//   try {
//     const res = await gateWay.get(userEndpoints.signUp, { params });
//     if (res.status === 200) {
//       const userDetails = res.data;
//       if (userDetails.success) {
//         await dispatch({ type: Auth.LIST_USER_SUCCESS, data: userDetails.data });
//       } else {
//         await dispatch({ type: Auth.LIST_USER_FAILURE, error: userDetails });
//       }
//     } else {
//       await dispatch({ type: Auth.LIST_USER_FAILURE, error: res.data });
//     }
//   } catch (e) {
//     await dispatch({ type: Auth.LIST_USER_FAILURE, error: e.response && e.response.data });
//   }
// };

export const validateEmail = (params) => async (dispatch) => {
  let snackbarOptions = { open: true };
  await dispatch({ type: Auth.VALIDATE_EMAIL_LOADING, isLoading: true });
  try {
    const res = await gateWay.post(userEndpoints.validateEmail, params);
    const mailResponse = res.data;
    if (mailResponse.success) {
      await dispatch({ type: Auth.VALIDATE_EMAIL_SUCCESS, data: mailResponse });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "success",
        message: mailResponse.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    } else {
      await dispatch({ type: Auth.LOGIN_FAILURE, isLoading: false });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "error",
        message: mailResponse.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    }
  } catch (e) {
    await dispatch({
      type: Auth.LOGIN_FAILURE,
      isLoading: false,
      error: e.response && e.response.data,
    });
    snackbarOptions = {
      ...snackbarOptions,
      variant: "error",
      message: e.response.data.data.message,
    };
    await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
  }
};

export const disciplineList = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.disciplineList);
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({
          type: Auth.DISCIPLINE_LIST_SUCCESS,
          data: userDetails.data,
        });
      } else {
        await dispatch({
          type: Auth.DISCIPLINE_LIST_FAILURE,
          error: userDetails,
        });
      }
    } else {
      await dispatch({ type: Auth.DISCIPLINE_LIST_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({
      type: Auth.DISCIPLINE_LIST_FAILURE,
      error: e.response && e.response.data,
    });
  }
};

export const degreeList = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.degreeList);
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({
          type: Auth.DEGREE_LIST_SUCCESS,
          data: userDetails.data,
        });
      } else {
        await dispatch({ type: Auth.DEGREE_LIST_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.DEGREE_LIST_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({
      type: Auth.DEGREE_LIST_FAILURE,
      error: e.response && e.response.data,
    });
  }
};

export const profileUpdate = (params) => async (dispatch) => {
  let snackbarOptions = { open: true };
  await dispatch({ type: Auth.PROFILE_UPDATE, isLoading: true });
  try {
    const res = await gateWay.post(userEndpoints.profileUpdate, params);
    const UpdatedProfile = res.data;
    if (UpdatedProfile.success) {
      await dispatch({ type: Auth.PROFILE_UPDATE, data: UpdatedProfile });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "success",
        message: UpdatedProfile.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
      return Promise.resolve({ success: true, data: UpdatedProfile });
    } else {
      await dispatch({ type: Auth.PROFILE_UPDATE, isLoading: false });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "error",
        message: UpdatedProfile.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
      return Promise.resolve({ success: false });
    }
  } catch (e) {
    await dispatch({
      type: Auth.PROFILE_UPDATE,
      isLoading: false,
      error: e.response && e.response.data,
    });
    snackbarOptions = {
      ...snackbarOptions,
      variant: "error",
      message: e.response.data.data.message,
    };
    await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    return Promise.resolve({ success: false });
  }
};

export const ChangePassword = (params) => async (dispatch) => {
  let snackbarOptions = { open: true };
  await dispatch({ type: Auth.RESET__PASSWORD_LOADING, isLoading: true });
  try {
    const res = await gateWay.post(userEndpoints.changePassword, params);
    const ChangePassword = res.data;
    if (ChangePassword.success) {
      await dispatch({ type: Auth.RESET__PASSWORD_SUCCESS, data: ChangePassword });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "success",
        message: ChangePassword.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
      return Promise.resolve({ success: true, data: ChangePassword });
    } else {
      await dispatch({ type: Auth.RESET__PASSWORD_FAILURE, isLoading: false, data: ChangePassword });
      snackbarOptions = {
        ...snackbarOptions,
        variant: "error",
        message: ChangePassword.message,
      };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
      return Promise.resolve({ success: false });
    }
  } catch (e) {
    await dispatch({
      type: Auth.RESET__PASSWORD_FAILURE,
      isLoading: false,
      error: e.response && e.response.data,
    });
    snackbarOptions = {
      ...snackbarOptions,
      variant: "error",
      message: e.response.data.data.message,
    };
    await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    return Promise.resolve({ success: false });
  }
};
